@import url('https://fonts.googleapis.com/css2?family=DM+Mono:wght@300;400;500&display=swap');

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'DM Mono', monospace;
    /* color: #8c38ff; */
}

:root{
    --primary: #8c38ff;
    --light: #EEEEEE;
    --dark: #212121;
}

html{
     background: #1e1a20;
     scroll-behavior: smooth;
     scroll-snap-type: y mandatory;
}

.webgl{
    position: fixed;
    top: 10px;
    left: 0px;
    outline: none;
}

.container{
    max-width: 1280px;
    margin: 0 auto;
    
    display: flex;
    align-items: center;
    justify-content: space-between;
}

nav{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 99;
    background-color: var(--dark);
    padding: 16px 32px;
    border-bottom: 3px solid var(--primary);
}

.container h1{
    color: #FFF;
    font-size: 28px;
    font-weight: 900;
    letter-spacing: 2px;
    text-transform: uppercase;
}

.hamburger{
    display: block;
    position: relative;
    z-index: 1;

    user-select: none;

    appearance: none;
    border: none;
    outline: none;
    background: none;
    cursor: pointer;
}

.hamburger span{
    display: block;
    width: 33px;
    height: 4px;
    margin-bottom: 5px;
    position: relative;

    background-color: var(--light);
    border-radius: 6px;
    z-index: 1;

    transform-origin: 0 0;

    transition: 0.4s;
}

.hamburger:hover span:nth-child(2){
    transform: translateX(10px);
    background-color: var(--primary);
}

.hamburger.is-active span:nth-child(1){
    transform: translate(0px, -2px) rotate(45deg);
}

.hamburger.is-active span:nth-child(2){
    opacity: 0;
    transform: translateX(15px);
}

.hamburger.is-active span:nth-child(3){
    transform: translate(-3px, 3px) rotate(-45deg);
}

.hamburger.is-active:hover span{
    background-color: var(--primary);
}

.mobile-nav{
    position: fixed;
    top: 0;
    left: 100%;
    width: 100%;
    min-height: 30vh;
    display: block;
    z-index: 98;
    padding-top: 120px;
    transition: 0.4s;
}

.mobile-nav a{
    display: block;
    width: 100%;
    max-width: 200px;
    margin: 0 auto 16px;
    text-align: center;
    padding: 12px 16px;
    background-color: #1f103f;

    color: #FFF;
    text-decoration: none;
}

.mobile-nav a:hover{
    background-color: #24104f;
}

.mobile-nav.is-active{
    top: auto;
    left: 0;
}

.menu{
    display: none;
    flex: 1 1 0%;
    justify-content: flex-end;
    margin: 0 -16px;
}

.menu a {
    color: #FFF;
    margin: 0 16px;
    font-weight: 800;
    text-decoration: none;
    transition: 0.4s;
    padding: 8px 16px;
    border-radius: 99px;
}
.menu a:hover{
    background-color: var(--primary);
}

.menu a.is-active{
    background-color: #435680;
}


.section{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    position: relative;
    color: #ffeded;
    /* text-transform: uppercase; */
    /* font-size: 3vmin; */
    padding-left: 10%;
    padding-right: 10%;
    /* scroll-snap-align: start; */
}

#home img{
    width: 35%;
    aspect-ratio: 3/3;
    object-fit: contain;
    filter: grayscale();
    border-radius: 50%;
}
section#home{
    flex-direction: row;
}

.card-list{
    display: flex;
    flex-direction: column;
    /* flex-wrap: wrap; */
    padding: 9rem;
    overflow-x: scroll;
    text-transform:none;
    padding: 30px;
}

/* .card-list:first-child{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    font-size: 40px;
} */

.card-list::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}
.card-list::-webkit-scrollbar-thumb {
    background: #201c29;
    border-radius: 10px;
    box-shadow: inset 2px 2px 2px hsla(0,0%,100%,.25), inset -2px -2px 2px rgba(0,0,0,.25);
}

.card-list::-webkit-scrollbar-track {
    background: linear-gradient(90deg,#201c29,#201c29 1px,#17141d 0,#17141d);
}

.card {
    height: 550px;
    width: 400px;
    min-width: 250px;
    padding: 1.5rem;
    border-radius: 16px;
    background: #17141d;
    box-shadow: -1rem 0 3rem #000;
    display: flex;
    flex-direction: column;
    transition: .2s;
    margin: 0;
    scroll-snap-align: start;
    clear: both;
    position: relative;
}

.card img{
    height: 200px;
    width: 300px;
    /* filter: grayscale(); */
}

.card:focus-within~.card, .card:hover~.card {
    transform: translateX(130px);
}

.card:hover {
    transform: translateY(-1rem);
}

.card:not(:first-child) {
    margin-left: -130px;
}

.card-author {
    position: relative;
    display: grid;
    grid-template-columns: 75px 1fr;
    align-items: center;
    margin: 3rem 0 0;
}

.card-header {
    margin-bottom: auto;
}

.card-header p {
    font-size: 14px;
    margin: 0 0 1rem;
    color: #7a7a8c;
}

.card-header h2 {
    font-size: 20px;
    margin: .25rem 0 auto;
    text-decoration: none;
    /* color: inherit; */
    color: white;
    border: 0;
    display: inline-block;
    cursor: pointer;
}

.card-header h2:hover {
    background: linear-gradient(90deg,#8c38ff,#212121);
    text-shadow: none;
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
}

.card-author {
    margin: 3rem 0 0;
    display: grid;
    grid-template-columns: 75px 1fr;
    align-items: center;
    position: relative;
}

.author-avatar {
    grid-area: auto;
    align-self: start;
    position: relative;
    box-sizing: border-box;
}

.author-avatar img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    /* filter: grayscale(100%); */
    display: block;
    overflow: hidden;
    margin: 16px 10px;
}

.author-name {
    grid-area: auto;
    box-sizing: border-box;
}

.author-name-prefix {
    font-style: normal;
    font-weight: 700;
    color: #7a7a8c;
}

.author-name-prefix:hover {
    /* background: linear-gradient(90deg,#ff8a00,#e52e71); */
    font-weight: 200;
}

.half-circle {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 60px;
    height: 48px;
    fill: none;
    stroke: #ff8a00;
    stroke-width: 8;
    stroke-linecap: round;
    pointer-events: none;
}

.tags {
    margin: 1rem 0 2rem;
    padding: .5rem 0 1rem;
    line-height: 2;
    margin-bottom: 0;
}

.tags a {
    font-style: normal;
    font-weight: 700;
    font-size: .5rem;
    color: #7a7a8c;
    text-transform: uppercase;
    font-size: .66rem;
    border: 3px solid #28242f;
    border-radius: 2rem;
    padding: .2rem .85rem .25rem;
    position: relative;
}

.tags a:hover {
    background: linear-gradient(90deg,#8c38ff,#212121);
    text-shadow: none;
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    /* -webkit-box-decoration-break: clone; */
    background-clip: text;
    border-color: white;
}

#contact-me a{
    color: white;
    ;
}


@media (min-width: 1030px){
    .hamburger{
        display: none;
    }
    .menu{
        display: flex;
    }
    .card-list{
    display: flex;
    flex-direction: row;
    }
    .section{
        scroll-snap-type: y mandatory;
    }
    .section{
        scroll-snap-align: Start;
    }
}
@media (max-width: 1031px){
    .section{
        scroll-snap-type: y mandatory;
    }
}